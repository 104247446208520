<template>
  <div class="page-content" :style="{ backgroundColor: bg ? bg : '#F5F7FA' }">
    <div v-if="isHeader">
      <van-nav-bar
        :title="title || routeTitle"
        :left-arrow="isBack"
        @click-left="onClickLeft"
      >
        <template #right>
          <slot name="tools" />
        </template>
      </van-nav-bar>
    </div>
    <div class="page-wrap">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bg: {
      typr: String,
      default: '#F5F7FA',
    },
    title: {
      typr: String,
      default: '',
    },
    isHeader: {
      type: Boolean,
      default: false,
    },
    isBack: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    routeTitle() {
      return this.$route.meta.title;
    },
  },
  methods: {
    onClickLeft() {
      this.$router.back();
      this.$emit('back');
    },
  },
};
</script>

<style lang="scss" scoped>
.page-content {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .page-wrap {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: inherit;
    -webkit-overflow-scrolling: touch;
  }
}
</style>
