import '@/assets/css/index.scss';
import '@/assets/iconfont/iconfont.css';
import Page from '@/components/Page';
import Dialog from '@/components/Dialog.vue';
import request from '@/request/interceptors.js';
import filter from '@/utils/filter';
import 'lib-flexible';
import 'nprogress/nprogress.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import VConsole from 'vconsole';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
Vue.component('Page', Page);
Vue.component('Dialog', Dialog);
Vue.use(Vant);
Vue.use(filter);
Vue.config.productionTip = false;
// 空值判断
Vue.prototype.isEmpty = (value) => {
  let tempValue = JSON.stringify(value),
    emptys = ['', 'undefined', 'null', '[]', '{}'];
  return emptys.includes(tempValue);
};
console.log('当前环境变量-------------->', process.env.VUE_APP_ENV);
console.log('当前接口地址-------------->', process.env.VUE_APP_BASE_API);
// 控制台
if (process.env.VUE_APP_ENV == 'test' || process.env.VUE_APP_ENV == 'preprod') {
  new VConsole();
}
// 请求管理器
Vue.prototype.$request = request;
window.$appId = 'RECHARGE_CARD_CONSUMER';
Vue.prototype.$baseImg = 'http://lerke.oss-cn-shenzhen.aliyuncs.com/';
const vm = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
// vue报错处理
Vue.prototype.toJSON = () => {
  return vm;
};
