import Nprogress from 'nprogress'
import Vue from 'vue'
import VueRouter from 'vue-router'
import NavConfig from "./config"
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  routes: NavConfig.route
})

router.beforeEach((to, form, next) => {
  console.log("跳转之前", to, form)
  Nprogress.start()
  next()
})
router.afterEach(() => {
  Nprogress.done()
});

export default router
