<template>
  <div class="page">
    <keep-alive :max="3" :exclude="['Record', 'Redirect', 'Recharge', 'Bind', 'User']">
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
export default {

}
</script>