import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import card from './modules/card'
import entery from './modules/entery'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    entery,
    card
  }
})
