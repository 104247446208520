<template>
  <div id="app">
    <layout />
  </div>
</template>

<script>
import layout from "@/layouts"
import { mapActions, mapMutations } from 'vuex'
export default {
  components: {
    layout
  },
  mounted() {
    const ORG_ID = localStorage.getItem("ORG_ID")
    let localCardInfo = JSON.parse(localStorage.getItem("CARD_INFO"))
    let localCardList = JSON.parse(localStorage.getItem("CARD_LIST")) || []
    let localMemberInfo = JSON.parse(localStorage.getItem("MEMBER_INFO"))
    this.SET_ORG_ID(ORG_ID || "")
    this.setMemberInfo(localMemberInfo || {})
    this.setCardInfo(localCardInfo || {})
    this.SET_CARD_LIST(localCardList || [])
  },
  methods: {
    ...mapActions("entery", ["setMemberInfo"]),
    ...mapActions("card", ["setCardInfo"]),
    ...mapMutations("app", ["SET_ORG_ID"]),
    ...mapMutations("card", ["SET_CARD_LIST"])
  }
}
</script>
<style lang="scss">
#app {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, 微软雅黑, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  overflow-x: hidden;
  font-size: 16px;
}
#nprogress {
  .bar {
    background: #F8B500 !important; //自定义颜色
  }
  .spinner-icon {
    border-top-color: #F8B500 !important;
    border-left-color: #F8B500 !important;
  }
}
</style>
