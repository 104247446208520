import axios from 'axios';
import { Toast } from 'vant';
import router from '../router/index';
import getSignOptions from './getSignOptions';
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
axios.defaults.timeout = 60000; //60s
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded;charset=UTF-8';

//POST传参序列化
function resetAllStorage() {
  localStorage.clear();
  // 接口返回重定向
  router.replace({
    path: 'card/list',
    query: {
      status: 0,
    },
  });
}
axios.interceptors.request.use(
  (config) => {
    // console.log(config, "config");
    let token = localStorage.getItem('TOKEN');
    if (token) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.common['authorization'] = token;
    }
    config.headers = {
      ...config.headers,
      'Content-Type': 'application/json;charset=UTF-8',
      'Dahuange-User-Access-Token': localStorage.getItem('casToken') || '',
    };
    const signOptions = getSignOptions(config);
    config.headers = {
      ...config.headers,
      ...signOptions,
    };
    console.log(
      `%c<-------${config.url}-------->`,
      'color: red;font-size: 18px',
      config.data || config.params
    );
    return config;
  },
  (error) => {
    Toast({
      message: '请求方式错误',
      duration: 3000,
    });
    return Promise.reject(error);
  }
);
//code状态码200判断
axios.interceptors.response.use(
  (response) => {
    const { code, message } = response.data;
    if (code == 10002 || code == 10006 || code == 10005 || code == 10034) {
      // 10002 token已经过期，10006登录状态失效
      resetAllStorage();
      return response;
    }
    const WHITE_CODES = [83001];
    if (
      (response && response.data && code < 0) ||
      (code && code !== 200 && code !== 100)
    ) {
      // 更改错误码，增加情况，当code不为100，不为200，且code存在的时候
      if (!WHITE_CODES.includes(code) && message) {
        Toast({
          message: message,
          duration: 2000,
        });
      }
      return Promise.reject(response.data);
    }
    return response.data;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          console.log('接口返回401！！！！！！！！！！！！！');
          return Promise.reject(error.response.data); // 返回接口返回的错误信息
      }
      if (error.response.status !== 400) {
        Toast({
          message: error.message,
          duration: 3000,
        });
      }
    } else {
      Toast({
        message:
          error.code !== 'ECONNABORTED'
            ? error.message
            : '加载超时，请稍后重试',
        duration: 3000,
      });
    }

    return Promise.reject(error);
  }
);

export default axios;
