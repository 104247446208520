import card from "./card"
export default {
  title: 'card',
  route: [
    {
      path: '/redirect',
      name: 'Redirect',
      meta: {
        title: '跳转中',
      },
      component: () =>
        import(/* webpackChunkName: "redirect" */ '@/views/redirect.vue'),
    },
    {
      path: '*',
      redirect: '/redirect',
    },
    ...card,
  ],
}