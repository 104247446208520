import request from '@/request/interceptors';

// 获取用户储值卡列表
export function getUserCardListApi(params) {
  return request({
    url: 'service/marketing/memberCard/h5/v1/listUserCard',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 获取用户储值卡信息
export function getCardInfoApi(params) {
  return request({
    url: 'service/marketing/memberCard/h5/v1/getCardInfo',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 更新储值卡消费限制
export function updateLimitApi(data) {
  return request({
    url: 'service/marketing/memberCard/h5/v1/updateLimit',
    method: 'post',
    data: {
      ...data,
    },
  });
}

// 更新储值卡状态
export function updateCardStatusApi(data) {
  return request({
    url: 'service/marketing/memberCard/h5/v1/updateStatus',
    method: 'post',
    data: {
      ...data,
    },
  });
}

// 更新储值卡状态
export function openCardlessPaymentApi(data) {
  return request({
    url: 'service/marketing/memberCard/h5/v1/openCard',
    method: 'post',
    data: {
      ...data,
    },
  });
}

//领取会员卡
export function receiveVipCard(data) {
  return request({
    url: 'service/marketing/memberCard/h5/v1/receiveCard',
    method: 'post',
    data: {
      ...data,
    },
  });
}

// 获取当前手机号订单情况
export function checkPhoneOrderApi(data) {
  return request({
    url: 'service/marketing/memberCard/h5/v1/checkPhoneOrder',
    method: 'post',
    data: {
      ...data,
    },
  });
}

// /service/marketing/memberCard/h5/v1/checkElectronCardPay
// 获取机构是否开启电子卡支付
export function checkElectronCardPayApi(params) {
  return request({
    url: 'service/marketing/memberCard/h5/v1/checkElectronCardPay',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function getMerchantCardInfo(params) {
  return request({
    url: 'service/marketing/coupon/memberCouponCardConfig/v1/h5GetCard',
    method: 'get',
    params: {
      ...params,
    },
  });
}

//根据卡号序列号获取单位

export function getEmployerApi(params) {
  return request({
    url: 'service/marketing/memberCard/h5/v1/getMemberCardInfo',
    method: 'get',
    params: {
      ...params,
    },
  });
}

export function getCardList(params) {
  return request({
    url: 'service/marketing/memberCard/h5/v1/listCardByOrgId',
    method: 'get',
    params: {
     ...params,
    },
  });
}
