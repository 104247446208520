import sign from './sign';

export default function getSignOptions(config = {}) {
  let appSecret = '';
  if (
    process.env.VUE_APP_ENV == 'production' ||
    process.env.VUE_APP_ENV == 'preprod'
  ) {
    appSecret = 'y8Bz0ftyymo2@3mg';
  } else {
    appSecret = 'DheL!234';
  }
  let signOptions = sign(window.$appId, appSecret, config);
  return signOptions;
}
