export default {
  namespaced: true,
  state: {
    memberInfo: {
      phone: "",
      memberNo: ""
    },

  },
  mutations: {
    SET_MEMBER_INFO(state, payload) {
      state.memberInfo = payload
    }
  },
  actions: {
    setMemberInfo({ commit }, payload) {
      localStorage.setItem("MEMBER_INFO", JSON.stringify(payload))
      commit("SET_MEMBER_INFO", payload)
    }
  },
  getters: {}
}