<template>
  <div>
    <van-dialog
      v-model="show"
      :overlayStyle="{ height: ' 100vh' }"
      :width="theme ? 300 : 320"
      :theme="theme"
      :show-cancel-button="showCancelButton"
      :show-confirm-button="!Boolean(theme)"
      confirmButtonColor="#F8B500"
      :confirmButtonText="confirmButtonText || '确定'"
      :cancelButtonText="cancelButtonText || '取消'"
      @confirm="onConfirm"
      @cancel="onCancel"
    >
      <div v-if="!theme" class="lk-font-md lk-p-24 lk-text-center">
        <div v-if="title" class="">{{ title }}</div>
        <div
          class="lk-p-t-16 lk-lh-1"
          :class="{
            'font-3': title,
          }"
        >
          <div>{{ content }}</div>
          <div
            v-if="cardList && cardList.length !== 0"
            class="lk-p-t-14"
            style="color: #999999"
          >
            <div v-for="(item, idx) in cardList" :key="idx">
              卡号：{{ item }}
            </div>
          </div>
        </div>
      </div>
      <div v-else class="lk-font-md lk-p-t-24 lk-m-lr-50 lk-text-center">
        <div class="lk-lh-1 lk-p-lr-5">{{ content }}</div>
        <div class="lk-lh-1">
          <div
            v-if="cardList && cardList.length == 3"
            class="lk-p-t-14"
            style="color: #999999"
          >
            <div v-for="(item, idx) in cardList" :key="idx">
              卡号：{{ item }}
            </div>
          </div>
        </div>
        <van-button
          class="font-1 lk-m-t-16"
          color="#F8B500"
          block
          round
          @click="onConfirm"
          >{{ confirmButtonText || '我知道了' }}</van-button
        >
      </div>
      <!-- <div>{{ title }}</div> -->
      <!-- <img src="https://img01.yzcdn.cn/vant/apple-3.jpg" /> -->
    </van-dialog>
  </div>
</template>

<script>
export default {
  bane: 'LkDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },

    content: {
      type: String,
      default: '',
    },
    theme: {
      // round-button
      type: String,
      default: '',
    },
    confirmButtonText: {
      type: String,
      default: '',
    },
    cancelButtonText: {
      type: String,
      default: '',
    },
    cardList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    visible: {
      handler(n) {
        this.show = n;
      },
    },
  },
  methods: {
    onConfirm() {
      this.onCancel();
      this.$emit('confirm');
    },
    onCancel() {
      this.$emit('cancel');
      this.$emit('update:visible', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.lk-lh-1 {
  line-height: 24px;
}
.lk-p-lr-5 {
  padding: 0 5px;
}
.lk-m-lr-50 {
  padding: 0 50px;
}
</style>
