import { Base64 } from './base64';
export const NumberCut = (value) => {
  if (!value) return '';
  return value
    .toString()
    .replace(/\s/g, '')
    .replace(/(\w{4})(?=\w)/g, '$1 ');
};

/*
 * params: {String}
 * 手机号脱敏处理
 */

export const phoneFmt = (value) => {
  if (!value) return '';
  return value.replace(/^(\d{3})\d{4}(\d{4})$/, '$1 **** $2');
};

/*
 * params: {username:String, password: String}
 * 密码
 */
export const cipherPassword = (username, password) => {
  let result = '';
  if (!username) {
    result = Base64.encode(password);
  }
  if (username && password) {
    let baseUserPass = Base64.encode(password + username);
    result = Base64.encode(window.$appId + baseUserPass);
  }
  return result;
};
